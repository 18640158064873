.cFooterWrapper{
    width: 100%;

margin-top: 5rem;
}


.cFooterWrapper>hr{
    width: 100%;


    height: 1px;
    border: none;
    background: white;
margin-top: 1rem;
margin-bottom: 2rem;
}

.cFooter{
    display: flex;
    width: 100%;
    justify-content: space-around;
}
.logo{
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}
 

.logo>img{
    width: 2.5rem;
    height: 2.5rem;

}

.logo>span{
    font-weight: 600;
    font-size: 1rem;
}

.block{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 12rem;

}

.detail{
    display: flex;
    flex-direction: column;
    width: inherit;
    font-size: .7rem;
    gap: 1rem;
    font-style: italic;
}

.detail>span:nth-of-type(1){
    font-weight: 500;
    font-size: 1.2rem    ;

font-style: normal;
}



.pngLine:hover{
    cursor: pointer;
    color:#fe956f;
    cursor: pointer;
    transition: all ease 1s;
}


.pngLine{
    display: flex;
    gap: 1rem;

align-items: center;


}

.icon{
    width: 25px;
    height: 25px;
}

.CopyRight{
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    font-size: .65rem   ;
    margin-top: 5rem;

}
@media screen and (max-width: 768px){
    .cFooter{
        justify-content: center;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        font-size: 1.7rem;
gap: 2rem;
    }
}
@media screen and (max-width: 640px){
.cFooter{
    justify-content: center;
}
.logo{
    display: none;
}



}