.container{
    padding: 1.5rem;
    display: flex;
}

.logo{
    display: flex;
    align-items: center;
    flex: 1;
    gap: 1rem   ;

}

.logo>img{
    width: 2.5rem;
    height: 2.5rem;
}

.logo>span{
    font-weight: 800;
}
.right{
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
  
}

.menu{
    display: flex;
    gap: 2rem;
font-weight: 700;
list-style: none;

}

.menu>li:hover{
    color:#fe956f;
    cursor: pointer;
    transition: all ease 1s;
}

.search{
    width: 10rem;
    outline: none;
    border:none;
    display: block;
    border-radius: 5px;
 
    padding: 0.5rem;
    background: rgba(198, 198, 198, 0.412); 
}
.texfiel{
    display: block;
}

.input {display:block}
input[type="text"]:focus{
border: 2px solid black;
background: rgba(0, 255, 229, 0.235); 
color:rgb(18, 99, 206);
  outline: 0;
  display: block;
}

.cart{
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
}
.cart:hover{
color: var(--pink);
cursor: pointer;
color:#fe956f;
cursor: pointer;
transition: all ease 1s;}

.bars{
    display: none;

}

@media screen and (max-width:856px) {
    .search{
    }
        
        .right{
        gap: 1rem;
        }}

@media screen and (max-width:640px) {
    .right{
        position: absolute;
        right: 2rem;
        z-index: 9999;
        background: white;
        color: var(--black);
        border-radius: 10px;
        flex-direction: column;
padding: 1rem;
text-align: center;
    }

    .menu{
        flex-direction: column;
        margin-left: -2rem;
    }
    .bars{
        display: block;
        

    }
    .bars:hover{
    color: var(--pink);
    cursor: pointer;
    color:#fe956f;
    cursor: pointer;
    transition: all ease 1s;}
    
    .search{

}
}
